import Vue from 'vue'
import VueRouter from 'vue-router'
import gtag from 'vue-gtag'
import top from '../components/top.vue'
import fctop from '../components/fctop.vue'
import fcBirthday from '../components/fcBirthday.vue'
import fcMovie from '../components/fcMovie.vue'
import fcMovieDetail from '../components/fcMovieDetail.vue'
import fcPic from '../components/fcPic.vue'
import fcPicDetail from '../components/fcPicDetail.vue'
import fcBlog from '../components/fcBlog.vue'
import fcBlogDetail from '../components/fcBlogDetail.vue'
import fcLetter from '../components/fcLetter.vue'
import fcWallpaper from '../components/fcWallpaper.vue'
import fcNewsletter from '../components/fcNewsletter.vue'
import fcNewsletterDetail from '../components/fcNewsletterDetail.vue'
import fcInfomation from '../components/fcInfomation.vue'
import fcInfomationDetail from '../components/fcInfomationDetail.vue'
import fcGoods from '../components/fcGoods.vue'
import fcGoodsDetail from '../components/fcGoodsDetail.vue'
import yumatokaraoke2024 from '../components/qn-yumatokaraoke.vue'
import yumatokaraoke2024Confirm from '../components/qn-yumatokaraokeConfirm.vue'
import yumatokaraoke2024Complete from '../components/qn-yumatokaraokeComplete.vue'
import questionforyuma2024 from '../components/qn-foryuma2024.vue'
import questionforyuma2024Confirm from '../components/qn-foryuma2024Confirm.vue'
import questionforyuma2024Complete from '../components/qn-foryuma2024Complete.vue'
// import qnDirectmypen from '../components/qn-directmypen.vue'
// import qnDirectmypenConfirm from '../components/qn-directmypenConfirm.vue'
// import qnDirectmypenComplete from '../components/qn-directmypenComplete.vue'
// import questionnaire from '../components/questionnaire.vue'
// import questionnaireConfirm from '../components/questionnaireConfirm.vue'
// import questionnaireComplete from '../components/questionnaireComplete.vue'
// import qnkanran2408 from '../components/qn-kanran20240821.vue'
// import qnkanran2408Confirm from '../components/qn-kanran20240821Confirm.vue'
// import qnkanran2408Complete from '../components/qn-kanran20240821Complete.vue'
import tokuten from '../components/tokuten.vue'
import keizoku from '../components/keizoku.vue'
import faq from '../components/faq.vue'
import faqEc from '../components/faqEc.vue'
import faqFc from '../components/faqFc.vue'
import fcQuestion from '../components/fcQuestion.vue'



Vue.use(VueRouter)
Vue.use(gtag, {
  config:{ id:'G-7QXE3V2MV2' },
},router)

const routes = [
  {
    path: '/',
    component: top
  },
  {
    path: '/fanclubtop',
    component: fctop
  },
  {
    path: '/fanclubtop/bc',
    component: fcBirthday
  },
  {
    path: '/fanclubtop/movie/list',
    component: fcMovie,
    children: [
      {
        path: '/fanclubtop/movie/list/:num',
      },
    ]
  },
  {
    path: '/fanclubtop/movie/detail/:movieid',
    component: fcMovieDetail
  },
  {
    path: '/fanclubtop/pic/list',
    component: fcPic,
    children: [
      {
        path: '/fanclubtop/pic/list/:num',
      },
    ]
  },
  {
    path: '/fanclubtop/pic/detail/:picid',
    component: fcPicDetail
  },
  {
    path: '/fanclubtop/blog/list',
    component: fcBlog,
    children: [
      {
        path: '/fanclubtop/blog/list/:num',
      },
    ]
  },
  {
    path: '/fanclubtop/blog/detail/:blogid',
    component: fcBlogDetail
  },
  {
    path: '/fanclubtop/letter',
    component: fcLetter
  },
  {
    path: '/fanclubtop/wallpaper/list',
    component: fcWallpaper,
    children: [
      {
        path: '/fanclubtop/wallpaper/list/:num',
      },
    ]
  },
  {
    path: '/fanclubtop/newsletter/list',
    component: fcNewsletter,
    children: [
      {
        path: '/fanclubtop/newsletter/list/:num',
      },
    ]
  },
  {
    path: '/fanclubtop/newsletter/detail/:nlid',
    component: fcNewsletterDetail
  },
  {
    path: '/fanclubtop/info/list',
    component: fcInfomation,
    children: [
      {
        path: '/fanclubtop/info/list/:num',
      },
    ]
  },
  {
    path: '/fanclubtop/info/detail/:infoid',
    component: fcInfomationDetail
  },
  {
    path: '/fanclubtop/goods/list',
    component: fcGoods,
    children: [
      {
        path: '/fanclubtop/goods/list/:num',
      },
    ]
  },
  {
    path: '/fanclubtop/goods/detail/:goodsid',
    component: fcGoodsDetail
  },
  {
    path: '/fanclubtop/questionnaire/questionforyuma20241129',
    component: questionforyuma2024,
  },
  {
    path: '/fanclubtop/questionnaire/questionforyuma20241129-confirm',
    component: questionforyuma2024Confirm,
  },
  {
    path: '/fanclubtop/questionnaire/questionforyuma20241129-complete',
    component: questionforyuma2024Complete,
  },
  {
    path: '/fanclubtop/questionnaire/yumatokaraoke2024',
    component: yumatokaraoke2024,
  },
  {
    path: '/fanclubtop/questionnaire/yumatokaraoke2024-confirm',
    component: yumatokaraoke2024Confirm,
  },
  {
    path: '/fanclubtop/questionnaire/yumatokaraoke2024-complete',
    component: yumatokaraoke2024Complete,
  },
  {
    path: '/tokuten',
    component: tokuten
  },
  {
    path: '/keizoku',
    component: keizoku
  },
  {
    path: '/faq',
    component: faq
  },
  {
    path: '/faq/EC',
    component: faqEc
  },
  {
    path: '/faq/FC',
    component: faqFc
  },
  {
    path: '/question/*',
    component: fcQuestion
  },
  {
    path: '/error/400',
    redirect: '/error/400.html'
  },
  {
    path: '/error/504',
    redirect: '/error/504.html'
  },
  {
    path: '/maintenance/',
    redirect: '/maintenance/maintenance.html',
  },
  {
    path: '*',
    redirect: '/fanclubtop',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  router['referrer'] = from;
  next();
})


export default router
