<template>
    <span>遷移します...</span>
</template>

<script>
  export default {
    data(){
      return{
        iam: process.env.VUE_APP_IAM,
        cart: process.env.VUE_APP_CARTURI,
        cms: process.env.VUE_APP_CLIENTAPI,
        path: this.$route.path,
        redirectFlg: this.$route.query.redirected 
      }
    },
    mounted(){
      setTimeout(this.redirectLogin,1000)
    },
    methods:{
      redirectLogin() {
        if(this.redirectFlg != 1 || !this.redirectFlg) {
            this.path = encodeURIComponent(this.path)
            this.rtn = encodeURIComponent(this.cms+'/system/login?action=login&to='+this.path)
            window.location.href = this.cart+'/v2/Login.aspx?ccode=BYU&ReturnUrl='+this.rtn
          } else {
            window.location.href = this.iam+'/fanclubtop'
          }
        }
      }
    }
</script>